import { Link } from "gatsby"
import React, { Component } from 'react';
import "../../styles/scss/pages/home/slider.scss"
//import "../../styles/scss/pages/home/popular.scss"
import Glide from '@glidejs/glide';

class DealOfTheDay extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const dealSliders = document.querySelectorAll('.glide');
        dealSliders.forEach((slider) => {
            new Glide(slider,{
              type: 'carousel',
              startAt: 0,
              perView: 3,
              breakpoints: {
                480: {
                  perView: 1,
                  peek: {
                    before:0,
                    after: 100,
                  }
                },
                767: {
                  perView: 2,
                  peek: {
                    before:0,
                    after:120,
                  }
                },
                991: {
                  perView: 2,
                  peek: {
                    before:0,
                    after:30,
                  }
                },
                1199: {
                  perView:2,
                  peek: {
                    before:0,
                    after:260,
                  }
                },
              }
            }).mount()
        });
    }
    render() {
        const { deals } = this.props;
        if(!Array.isArray(deals.data) || deals.data.length == 0) {
            return '';
        }
        return (
            <div className="slider home-deal">
                <div className="container">
                    <h2>{deals.title}</h2>
                    <div className="glide slider-wrapper">
                        <div className="glide__track" data-glide-el="track">
                            <ul className="glide__slides">
                                {deals.data.map((entity, i) => (
                                    <li key={entity.id + i} className="glide__slide">
                                        <Link to={entity.gatsbyUrl} className="deal-box">
                                            <div className="deal-img">
												<img src={entity.offer.store.logoUrl} alt={entity.offer.store.name + " Offer"} />
											</div>
                                            <div className="deal-footer">
                                                <h3>{entity.offer.name}</h3>
                                            </div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="glide__arrows slider-btns" data-glide-el="controls">
                            <button className="glide__arrow glide__arrow--left arrow prev" data-glide-dir="<">‹</button>
                            <button className="glide__arrow glide__arrow--right arrow next" data-glide-dir=">">›</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default DealOfTheDay