import { Link } from "gatsby"
import React from "react"
import "../../styles/scss/pages/home/banner.scss"

const Banner = ( {h1} ) => (
  <div className="banner">
    <div className="container">
      <h1>{h1}</h1>
      <a href="#Browse" className="subscribe-btn desktop">Subscribe Now!</a>
      <a href="#subscribe-wrapper" className="subscribe-btn mobile">Subscribe Now!</a>
    </div>
  </div>
)

export default Banner