import React from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layout';
import Banner from '../components/home/banner';
import HowItWorks from '../components/home/how-it-works';
import Subscribe from "../components/category/subscribe";
import DealOfTheDay from '../components/home/deal-of-the-day';
import PopularOffers from '../components/home/popular-offers';
import TopStores from '../components/home/top-stores';
import DH from "../utils/details-helpers";
import Helmet from "react-helmet"

const IndexPage = ( {pageContext} ) => {
	
	var ts = {}, tb = {}, td = {}, tp = {};
	let { topBankOffers, topStores, popularOffers, dayOffer } = pageContext;

	tb.title = "Top Banks Credit & Debit Card Offers";
	tb.data = topBankOffers;
	
	ts.title = "Top Stores Credit & Debit Card Offers";
	ts.data = topStores;
	
	td.title = "Deal of the Day";
	td.data = dayOffer;
	
	tp.title = "Popular Offers";
	tp.data = popularOffers;
	
	const offers_search_data = dayOffer.slice(0, 4);
	
	const seoData = {
		'title' : "India's first Credit & Debit cards based coupons, offers and rewards website!", 
		'hide_month' : true,
		'description' : 'HappyCredit is the shoppers new destination to find best active offers on all banks credit and debit cards for all brands. Your savings start with us!', 
		'canonicalUrl' : '/', 
		'robots' : 'index,follow',
		'h1' : "India's first Credit & Debit cards based coupons, offers and rewards website!"
	};
		
	return (
	<>	
		{offers_search_data.map((search_offer,i) => {
			return (	
				<Helmet key={i}>
					<script type="application/ld+json">{DH.getOfferSaleEventSchema(search_offer, "/")}</script>
				</Helmet>
			)
		})}
	<Layout page={seoData}>
		<div className="home-page">
			<Banner h1={seoData.h1}/>
			<HowItWorks />
			<div className="container" id="subscribe-wrapper">
				<Subscribe />
			</div>
			<DealOfTheDay deals={td}/>
			<div className="popular-deal-day"><PopularOffers deals={tp}/></div>
			{/*<PopularOffers />*/}
			<div className="other-offer-wrapper">
				<TopStores offers={tb}/>
				<TopStores offers={ts} />
			</div>
			{/* <RecentlyUsed /> */}
		</div>
	</Layout>
	</>
)};

export default IndexPage;