import React from "react"
import "../../styles/scss/pages/home/how-it-works.scss"

const HowItWorks = () => (
  <div className="how-it-works">
    <div className="container">
      <h2>How does it work?</h2>
      <div className="col c0l-1">
      <img src={'/images/glob.svg'} alt="browse offers" />
      <h3 id="Browse">Browse</h3>
      <p>Find card offers active at <br /> your favourite stores</p>
      </div>
      <div className="col c0l-2">
      <img src={'/images/cart.svg'} alt="shop on stores" />
      <h3>Shop</h3>
      <p>Click-through to store websites <br /> and complete your shopping</p>
      </div>
      <div className="col c0l-3">
      <img src={'/images/hands.svg'} alt="save with cards" />
      <h3>Save</h3>
      <p>Simply paste promo code and <br /> save with your card offers</p>
      </div>
    </div>
  </div>
)

export default HowItWorks

