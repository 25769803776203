import { Link } from "gatsby"
import React from "react"
import "../../styles/scss/pages/home/top-stories.scss"

const TopStores = ({ offers }) => {

	return (
		<div className="top-stores">
			<div className="container">
				<h2>{offers.title}</h2>
				<div className="stores-list">
					<ul>
						{offers.data.map(entity => {
							let offerCount = entity.count ? entity.count : entity.offers.length;
							let url = entity.page.gatsbyUrl || entity.page.url;
							return (
								<li key={entity.id}>
									<Link to={url}>
										<img src={entity.logoUrl} alt={entity.name + " Offer"} />
										<p>{offerCount} Offers</p>
									</Link>
								</li>
							)
						})}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default TopStores